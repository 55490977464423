import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const Index = ({ imgSrc, date, title, desc, isLTR }) => (
  <Container className="history-component">
    <Row className="mini-hide">
      {isLTR ? (
        <>
          <Col md={5} className="left-section">
            <img src={imgSrc} alt="sejarah" width="50%" />
          </Col>
          <Col md={1} className="history-year-column">
            <div className="history-year">
              <span>{date.slice(-4)}</span>
              <div className="vertical-line"></div>
            </div>
          </Col>
          <Col md={6} className="right-section">
            <label>{date}</label>
            <label>{title}</label>
            <div
              dangerouslySetInnerHTML={{ __html: desc }}
              className="content"
            />
            {/* <p>{desc}</p> */}
          </Col>
        </>
      ) : (
        <>
          <Col md={5} className="left-section">
            <label>{date}</label>
            <label>{title}</label>
            <div
              dangerouslySetInnerHTML={{ __html: desc }}
              className="content"
            />
          </Col>
          <Col md={1} className="history-year-column">
            <div className="history-year">
              <span>{date.slice(-4)}</span>
              <div className="vertical-line"></div>
            </div>
          </Col>
          <Col md={6} className="right-section">
            <img src={imgSrc} alt="sejarah" width="50%" />
          </Col>
        </>
      )}
    </Row>
    <Row className="history-show">
      <Col xs={12} className="history-year-column">
        <div className="history-year" style={{ marginBottom: 20 }}>
          <span>{date.slice(-4)}</span>
          {/* <div className="vertical-line"></div> */}
        </div>
      </Col>
      <Col xs={12}>
        <img
          src={imgSrc}
          alt="sejarah"
          width="100%"
          // style={{ marginLeft: 60, marginTop: "-20px" }}
        />
      </Col>
    </Row>
    <Row className="history-show">
      <Col md={12} xs={12} className="history-section">
        <label>{date}</label>
        <label style={{ marginTop: 40, fontSize: 20 }}>{title}</label>
        <div dangerouslySetInnerHTML={{ __html: desc }} className="content" />
      </Col>
    </Row>
  </Container>
)

export default Index
