import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../../components/layout/Index"
import Title from "../../components/commons/page/title/Index"
import History from "../../components/content/history/Index"
import { graphql } from "gatsby"
import { withTrans } from "../../i18n/withTrans"

const Index = ({ data, t, i18n }) => {
  const language = i18n.language
  const dataFilter = data?.allWpSejarah?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const sortByDate = dataFilter?.sort(
    (b, a) => new Date(a.history.tanggal) - new Date(b.history.tanggal)
  )
  return (
    <Layout>
      <Container className="page-container history">
        <Row className="justify-content-between align-items-center mb-4">
          <Col md={12} className="page-title m-0">
            <span>{t("historyOfGakeslabIndonesia")}</span>
          </Col>
        </Row>
        {/* <Title label={t('historyOfGakeslabIndonesia')} /> */}
        {sortByDate.map((item, idx) => {
          return (
            <History
              key={item?.history?.id}
              imgSrc={item?.history?.fotoKegiatan?.sourceUrl}
              date={item?.history?.tanggal}
              title={item?.title}
              desc={item?.history?.keterangan}
              isLTR={idx % 2 === 0}
            />
          )
        })}
      </Container>
    </Layout>
  )
}

export default withTrans(Index)

export const query = graphql`
  {
    allWpSejarah {
      nodes {
        language {
          code
        }
        history {
          tanggal
          keterangan
          fotoKegiatan {
            sourceUrl
          }
        }
        id
        title
      }
    }
  }
`
